<template>
  <div class="box-container">
    <div class="flex flex-align-items-center flex-justify-content-between">
      <p class="title--section no-margin">Search criteria</p>
      <div class="flex flex-align-items-center">
        <button v-if="showDeleteButton" class="cta _danger-border" style="margin-right: 20px" @click="$emit('onDeleteClick')">Delete</button>
        <button class="cta trigger display-popup" @click="showPopup()">Edit criteria +</button>
      </div>
    </div>
    <div v-if="item.bedroom_min === null && item.bedroom_max === null && item.bathroom_min === null && item.bathroom_max === null && item.max_sea_distance === null && !item.types.length && !item.specifications.length">
      <p class="u-mt-20">No search criteria yet</p>
    </div>
    <div class="tag-list" style="margin-top: 20px; margin-bottom: 20px;">
      <button class="tag _c-blue" v-if="item.come_over_date && item.come_over_date !== ''" @click="resetField('come_over_date', null)"><span>{{dateToDayMonth(item.come_over_date)}}</span><span>x</span></button>
      <button class="tag _c-blue" v-if="item.bedroom_min !== null" @click="resetField('bedroom_min', null)"><span>Min bedroom: {{item.bedroom_min}}</span><span>x</span></button>
      <button class="tag _c-blue" v-if="item.bedroom_max !== null" @click="resetField('bedroom_max', null)"><span>Max bedroom: {{item.bedroom_max}}</span><span>x</span></button>
      <button class="tag _c-blue" v-if="item.bathroom_min !== null" @click="resetField('bathroom_min', null)"><span>Min bathroom: {{item.bathroom_min}}</span><span>x</span></button>
      <button class="tag _c-blue" v-if="item.bathroom_max !== null" @click="resetField('bathroom_max', null)"><span>Max bathroom: {{item.bathroom_max}}</span><span>x</span></button>
      <button class="tag _c-blue" v-if="item.studio_min !== null && item.studio_min !== undefined" @click="resetField('studio_min', null)"><span>Min studio: {{item.studio_min}}</span><span>x</span></button>
      <button class="tag _c-blue" v-if="item.studio_max !== null && item.studio_max !== undefined" @click="resetField('studio_max', null)"><span>Max studio: {{item.studio_max}}</span><span>x</span></button>
      <button class="tag _c-grey" v-if="item.max_sea_distance !== null" @click="resetField('max_sea_distance')"><span>Max sea distance: {{item.max_sea_distance}}km</span><span>x</span></button>
      <button v-for="(item) in item.types" :key="item.id + 'types'" class="tag _c-olive" @click="removeType(item.id)"><span>{{getTypeNameById(item.id)}}</span><span>x</span></button>
      <button v-for="(item) in item.specifications" :key="item.id + 'specs'" class="tag _c-orange" @click="removeSpecification(item.id)"><span>{{getSpecificationNameById(item.id)}}</span><span>x</span></button>
    </div>
    <p class="title--section no-margin">Financial details</p>
    <div class="grid">
      <div class="grid__i--6-12">
        <div class="card-line">
          <span class="label">Budget min:</span>
          <div class="input-wrapper">
            <Input type="text" placeholder="100 000" v-model="price_min" />
            <span class="unit">{{ $store.getters.getCurrency }}</span>
          </div>
        </div>
      </div>
      <div class="grid__i--6-12">
        <div class="card-line">
          <span class="label">Budget max:</span>
          <div class="input-wrapper">
            <Input type="text" placeholder="100 000" v-model="price_max" />
            <span class="unit">{{ $store.getters.getCurrency }}</span>
          </div>
        </div>
      </div>
      <div class="grid__i--6-12">
        <div class="card-line">
          <span class="label">Buying reason:</span>
          <Select v-model="buying_reason" :defaultIsEmptyValue="true" :options="buyingReasonsSelectOptions" />
        </div>
      </div>
      <div class="grid__i--6-12">
        <div class="card-line">
          <span class="label">Financial:</span>
          <Select v-model="financial" :defaultIsEmptyValue="true" :options="financialSelectOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import notifBus from "@/bus/notification-bus";
import modalBus from "@/bus/modal-bus";
import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  props: {
    showDeleteButton: {
      type: [Boolean],
      required: false,
      default: true,
    },
    item: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
      buying_reason: null,
      financial: null,
      price_min: 0,
      price_max: 0,
      buyingReasonsSelectOptions: [],
      financialSelectOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      buyingReasons: "searchCriteriaOptions/buyingReasons",
      financialTypes: "searchCriteriaOptions/financialTypes",
      propertiesSpecifications: "searchCriteriaOptions/propertiesSpecifications",
      propertiesTypes: "searchCriteriaOptions/propertiesTypes",
    }),
  },
  methods: {
    ...mapActions({
      fetchOptions: "searchCriteriaOptions/fetchOptions",
    }),
    showPopup(){
      modalBus.$emit("change", {
        component: "AddSearchCriteria",
        data: JSON.parse(JSON.stringify(this.item))
      });
      modalBus.$emit("set-size", {
        width: "800px",
        height: "auto",
        "margin-top": "50px",
      });
      modalBus.$emit("toggle");
    },
    getTypeNameById(id){
      const item = this.propertiesTypes.find((item) => item.id === id);
      return item ? item.name : null;
    },
    getSpecificationNameById(id){
      const item = this.propertiesSpecifications.find((item) => item.id === id);
      return item ? item.name : null;
    },
    removeType(id){
      const foundIndex = this.item.types.findIndex((item) => item.id === id);
      if (foundIndex >= 0) {
        this.item.types.splice(foundIndex, 1);
      }
    },
    removeSpecification(id){
      const foundIndex = this.item.specifications.findIndex((item) => item.id === id);
      if (foundIndex >= 0) {
        this.item.specifications.splice(foundIndex, 1);
      }
    },
    resetField(field, value){
      this.item[field] = value != null && value !== undefined ? value : null;
    },
    // str format: YYYY-MM-DD
    dateToDayMonth(str){
      return this.moment(str, "YYYY-MM-DD").format("MMMM YYYY");
    }
  },
  watch: {
    buying_reason(value, oldValue){
      if (value && value !== oldValue) {
        this.$emit("change", {
          id: this.item.id,
          buying_reason: value,
          financial: this.financial,
          price_min: this.price_min,
          price_max: this.price_max,
        });
      }
    },
    financial(value, oldValue){
      if (value && value !== oldValue) {
        this.$emit("change", {
          id: this.item.id,
          buying_reason: this.buying_reason,
          financial: value,
          price_min: this.price_min,
          price_max: this.price_max,
        });
      }
    },
    price_min(value, oldValue){
      if (value && value !== oldValue) {
        this.$emit("change", {
          id: this.item.id,
          buying_reason: this.buying_reason,
          financial: this.financial,
          price_min: value,
          price_max: this.price_max,
        });
      }
    },
    price_max(value, oldValue){
      if (value && value !== oldValue) {
        this.$emit("change", {
          id: this.item.id,
          buying_reason: this.buying_reason,
          financial: this.financial,
          price_min: this.price_min,
          price_max: value,
        });
      }
    },
    //if (value && value !== oldValue) {}
  },
  async created() {
    await this.fetchOptions();
    this.buyingReasonsSelectOptions = this.buyingReasons.map((item) => {
      return {
        "name": item.name,
        "value": item.id,
      };
    });
    this.financialSelectOptions = this.financialTypes.map((item) => {
      return {
        "name": item.name,
        "value": item.id,
      };
    });
    if (this.item.buying_reason){
      this.buying_reason = this.item.buying_reason;
    }
    this.financial = this.item.financial;
    this.price_min = this.item.price_min;
    this.price_max = this.item.price_max;
    modalBus.$on("save-search-criteria", (data) => {
      if (this.item.id === data.id) {
        this.$emit("change", {
          id: this.item.id,
          buying_reason: this.buying_reason,
          financial: this.financial,
          price_min: this.price_min,
          price_max: this.price_max,
          ...data
        });
      }
    });
  }
});
</script>
