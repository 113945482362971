<template>
  <div style="margin-bottom: 30px">
    <SearchCriteriaItem v-for="(item, index) in items" :item="item"
                        :style="{'z-index': 999 - index}"
                        :show-delete-button="items.length > 1"
                        :key="item.id"
                        @onDeleteClick="showDeletePopup(item)"
                        @change="onChange"
    />
    <button @click="add">+ New search criteria</button>
  </div>
</template>

<script>
import Vue from "vue";
import notifBus from "@/bus/notification-bus";
import modalBus from "@/bus/modal-bus";
import SearchCriteriaItem from "./SearchCriteriaItem";

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export default Vue.extend({
  components: { SearchCriteriaItem },
  data() {
    return {
      items: [],
      defaultItem: {
        buying_reason: null,
        financial: null,
        price_min: 0,
        price_max: 0,
        bedroom_min: null,
        bedroom_max: null,
        bathroom_min: null,
        bathroom_max: null,
        max_sea_distance: null,
        come_over_date: undefined,
        types: [],
        specifications: [],
      },
    };
  },
  props: ["client", "initialItems"],
  methods: {
    add() {
      this.items.push({ ...this.defaultItem, id: uuidv4() });
    },
    showDeletePopup(item){
      const confirm = window.confirm("Are you sure you want to delete this search criteria ?");
      if (confirm) {
        this.delete(item);
      }
    },
    delete(item){
      const foundIndex = this.items.findIndex(loopItem => loopItem.id === item.id);
      if ( foundIndex >= 0 ) {
        this.items.splice(foundIndex, 1);
      }
    },
    onChange(item){
      // Replace item
      const foundIndex = this.items.findIndex((loopItem) => loopItem.id === item.id);
      if ( foundIndex >= 0 ) {
        // Overwrite the new item
        this.items.splice(foundIndex, 1, { ...this.items[foundIndex], ...item });
        this.$emit("change", this.items);
      }
    }
  },
  watch: {
    initialItems(value, oldValue){
      if (value && value !== oldValue) {
        if ( !value.length ) {
          this.items = [
            {...this.defaultItem, id: uuidv4()}
          ];
        } else {
          this.items = value;
        }
      }
    }
  },
  created() {
    if ( !this.items.length ) {
      this.items = [
        {
          id: uuidv4(),
          buying_reason: null,
          financial: null,
          price_min: 0,
          price_max: 0
        }
      ];
    }
  }
});
</script>
